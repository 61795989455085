import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import WatchdogDashboardPdf from '../pdf/watchdog/dashboard/watchdogPdf';
import MeasurmentPdf from '../pdf/watchdog/measurment/measurmentPdf';
import NotificationPdf from '../pdf/watchdog/eventLog/notification/notification';
import LogsPdf from '../pdf/watchdog/eventLog/logs/logs';
import StorageInsightPdf from '../pdf/storageInsight/dashboard/storageInisghtPdf';
import StorageInsightMesurment from '../pdf/storageInsight/measurment/measurment';
import NotificationPdfStorageInsight from '../pdf/storageInsight/eventLog/notification/notification';
import LogsPdfStorageInsight from '../pdf/storageInsight/eventLog/logs/logs';
import DcaMeasurement from '../pdf/dca/measurement/measurement';
import DcaDashboardPdf from '../pdf/dca/dashboard/dashboard';
import OpticontrolDashboardPdf from '../pdf/opticontrol/dashboard';
import SuccesMessage from '../container/success-message/index';
import ForgotPassword from '../container/profile/authentication/overview/ForgotPassword';

const publicRoutes = ({ initialUrl }) => {
  return (
    <Switch>
      <Route exact path="/pdf/watchdog/dashboard/:id" component={WatchdogDashboardPdf} />
      <Route exact path="/pdf/watchdog/measurment/:id" component={MeasurmentPdf} />
      <Route exact path="/pdf/watchdog/notification/:id" component={NotificationPdf} />
      <Route exact path="/pdf/watchdog/log/:id" component={LogsPdf} />
      <Route exact path="/pdf/storage-insight/dashboard/:id" component={StorageInsightPdf} />
      <Route exact path="/pdf/storage-insight/measurement/:id" component={StorageInsightMesurment} />
      <Route exact path="/pdf/storage-insight/notification/:id" component={NotificationPdfStorageInsight} />
      <Route exact path="/pdf/storage-insight/log/:id" component={LogsPdfStorageInsight} />
      <Route exact path="/pdf/dca/dashboard/:id" component={DcaDashboardPdf} />
      <Route exact path="/pdf/dca/measurement/:id" component={DcaMeasurement} />
      <Route exact path="/pdf/opticontrol/dashboard/:id" component={OpticontrolDashboardPdf} />
      <Route exact path="/success-message" component={SuccesMessage} />
      <Route exact path="/forgotPassword" component={ForgotPassword} />
      <Route exact path="*" render={() => <Redirect to={{ pathname: '/', state: { from: initialUrl } }} />} />
    </Switch>
  );
};
export default publicRoutes;
