import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Col, Form, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import DateInput from '../../../../components/inputs/date';
import SelectInput from '../../../../components/inputs/select';
import TextInput from '../../../../components/inputs/text';
import TextAreaInput from '../../../../components/inputs/text-area';
import CheckboxInput from '../../../../components/inputs/checkbox';
import { selectOptionValues } from '../../../../redux/selectOptions/actions';
import ChannelsService from '../../../../service/channels';
import OrchardsService from '../../../../service/orchards';

export const containerFields = [
  { field: 'name', type: 'text', label: 'Name', required: true },
  { field: 'site', type: 'select', label: 'Site', required: true },
  { field: 'notes', type: 'textArea', label: 'Notes' },
  { field: 'Channel', type: 'select', label: 'Channel' },
  {
    field: 'tags',
    type: 'select',
    label: 'Tags',
    mode: 'tags',
  },
  { field: 'is_optipallet', type: 'checkbox', label: 'Optipallet' },
];

export const fieldToOptions = (fieldProps, allOptions) => {
  return allOptions[
    `${fieldProps.field?.toLowerCase()}${
      fieldProps.mode && (fieldProps.mode === 'multiple' || fieldProps.mode === 'tags') ? '' : 's'
    }`
  ];
};

export const contentFields = [
  { field: 'grower', type: 'select', label: 'Grower', required: true },
  { field: 'orchard', type: 'select', label: 'Orchard', dependsOn: 'grower' },
  {
    field: 'treatments',
    type: 'select',
    label: 'Treatments',
    mode: 'multiple',
  },
  {
    field: 'smart_fresh',
    type: 'date',
    label: 'Smartfresh',
    required: true,
  },
  { field: 'cultivar', type: 'select', label: 'Cultivar', required: true },
  {
    field: 'palox',
    type: 'select',
    label: 'Package',
    options: [
      { id: 'Wood', name: 'Wood' },
      { id: 'Plastic', name: 'Plastic' },
    ],
  },
  { field: 'amount', type: 'text', label: 'Amount' },
  { field: 'harvest', type: 'date', label: 'Harvest Date' },
];

const channelService = new ChannelsService();
const orchardService = new OrchardsService();

const ContainerForm = ({ containerToConfigure, handleSubmit, handleSubmitDisabled, isContent, content }) => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(state => state.selectOptions);
  const existingValues = content || containerToConfigure;
  const [form] = Form.useForm();
  const selectedSiteId = Form.useWatch('site', form);
  const selectedGrowerId = Form.useWatch('grower', form);
  const { data: channelData, isFetched } = useQuery({
    queryKey: ['channels', containerToConfigure?.site_id, selectedSiteId],
    enabled: !!containerToConfigure?.site_id || !!selectedSiteId,
    queryFn: () => channelService.getChannelsBySite(selectedSiteId || containerToConfigure?.site_id),
    select: response => response.data.data,
  });
  const { data: orchardsByGrower, isFetched: orchardsFetched } = useQuery({
    queryKey: ['orchardsByGrower', selectedGrowerId],
    enabled: !!selectedGrowerId,
    queryFn: () => orchardService.getOrchards({ growers_id: selectedGrowerId }),
    select: response => response.data.data,
  });
  useEffect(() => {
    if (channelData?.length) {
      dispatch(selectOptionValues(channelData, 'channels'));
      const channelField = 'Channel';
      const incorrectValue = !channelData.find(o => o.id === form.getFieldValue(channelField));
      if (incorrectValue) {
        form.setFieldsValue({ ...form.getFieldsValue, [channelField]: undefined });
      }
    }
  }, [channelData, dispatch, form, isFetched]);
  useEffect(() => {
    if (orchardsByGrower) {
      dispatch(selectOptionValues(orchardsByGrower, 'orchards'));
    }
  }, [orchardsByGrower, form, dispatch, orchardsFetched]);
  const handleSelectChange = (field, value) => {
    form.setFieldsValue({ ...form.getFieldsValue, [field]: value });
  };
  const componentTypes = useMemo(
    () => ({
      text: fieldProps => <TextInput {...fieldProps} />,
      textArea: fieldProps => <TextAreaInput {...fieldProps} />,
      date: fieldProps => <DateInput {...fieldProps} />,
      select: fieldProps => (
        <SelectInput
          formInstance={form}
          handleSelectChange={handleSelectChange}
          {...fieldProps}
          options={fieldProps.options || fieldToOptions(fieldProps, selectOptions) || []}
        />
      ),
      checkbox: fieldProps => <CheckboxInput {...fieldProps} />,
    }),
    [selectOptions],
  );
  const convertFieldToInput = useCallback(
    field => {
      const currentFields = isContent ? contentFields : containerFields;
      const relevantField = currentFields.find(o => o.field === field);
      if (!relevantField) {
        return null;
      }
      const { type } = relevantField;
      return componentTypes[type](relevantField);
    },
    [componentTypes, isContent],
  );
  const treatmentIdsSelected = Form.useWatch('treatments', form);
  const treatmentsSelected = useMemo(
    () => treatmentIdsSelected?.map(id => selectOptions?.treatments.find(t => t.id === id)?.title),
    [selectOptions, treatmentIdsSelected],
  );
  const inputsInfo = useMemo(() => {
    const inputList = [];
    const valuesToFillIn = {};
    const fields = isContent ? contentFields : containerFields;
    fields.forEach(fieldData => {
      const { field, type, options, required } = fieldData;
      const input = convertFieldToInput(fieldData.field);
      if (input) {
        inputList.push([input, fieldData]);
        if (existingValues) {
          const value = existingValues[field];
          if (type === 'date') {
            valuesToFillIn[field] = value ? moment(value) : null;
          } else if (field === 'treatments') {
            if (value) {
              valuesToFillIn[field] = value.map(treatment => treatment.id);
            } else if (existingValues.room_content_to_room_content_treatment?.length) {
              valuesToFillIn[field] = existingValues.room_content_to_room_content_treatment.map(
                contentTreatment => contentTreatment.room_content_treatment_to_treatment?.id,
              );
            }
          } else if (field === 'tags') {
            valuesToFillIn[field] = value?.map(tag => tag.id);
          } else if (type === 'select' && !options?.length) {
            valuesToFillIn[field] = value?.id;
          } else {
            valuesToFillIn[field] = value;
          }
        } else if (type === 'select') {
          const fieldSelectOptions = fieldToOptions(fieldData, selectOptions);
          if (fieldSelectOptions?.length === 1 && required) {
            valuesToFillIn[field] = fieldSelectOptions[0]?.id;
          }
        }
      }
      return input;
    });
    const initialValue = form.getFieldsValue()?.name;
    if (!initialValue && !selectedSiteId) {
      form.setFieldsValue(valuesToFillIn);
    }
    return inputList;
  }, [convertFieldToInput, existingValues, form, isContent, selectOptions, selectedSiteId]);

  const inputsToDisplay = useMemo(() => {
    return inputsInfo
      .filter(info => {
        const [, fieldData] = info;
        const { field } = fieldData;
        if (field === 'smart_fresh' && !treatmentsSelected?.includes('SmartFresh')) {
          return false;
        }
        return true;
      })
      .map(info => info[0]);
  }, [inputsInfo, treatmentsSelected]);

  const resetAndSubmit = formRecord => {
    handleSubmit(formRecord);
    if (isContent && !content?.id) {
      form.resetFields();
    }
  };

  return (
    <>
      <Form
        form={form}
        id={isContent ? 'content-configuration' : 'container-configuration'}
        onFinish={resetAndSubmit}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 43 }}
        onFieldsChange={() =>
          handleSubmitDisabled(form.getFieldsError().filter(({ errors }) => errors.length).length > 0)
        }
        style={{
          margin: '1rem 0',
          width: '100%',
        }}
      >
        <Row style={{ margin: '0 10px 0 0' }}>
          <Col style={{ margin: '0 5px 0 -1rem' }} span={11}>
            {inputsToDisplay?.filter(
              (_, i) => (inputsToDisplay.length % 2 ? i : i + 1) <= Math.floor(inputsToDisplay.length / 2),
            )}
          </Col>
          <Col span={12}>
            {inputsToDisplay?.filter(
              (_, i) => (inputsToDisplay.length % 2 ? i : i + 1) > Math.floor(inputsToDisplay.length / 2),
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContainerForm;
