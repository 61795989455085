import { useMutation } from '@tanstack/react-query';
import { Select, Form, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagService from '../../../service/tags';
import { selectOptionValues } from '../../../redux/selectOptions/actions';

const { Option } = Select;
const tagService = new TagService();

const SelectInput = ({ field, required, label, options, mode, handleSelectChange, formInstance, dependsOn }) => {
  const dispatch = useDispatch();
  const globalStates = useSelector(state => state?.Dashboard);
  const [selectedValue, setSelectedValue] = useState();
  const dependsOnInputValue = dependsOn ? Form.useWatch(dependsOn, formInstance) : null;
  const savingTag = useMutation({
    mutationFn: tag => tagService.saveTag(tag),
    onSuccess: (data, variables) => {
      notification.success({
        message: `Saved tag "${variables?.name}" to company!`,
      });
      const tag = data?.data?.data;
      dispatch(selectOptionValues([tag, ...options], 'tags'));
      const newSelectedState = [...selectedValue.filter(t => typeof t === 'number'), tag.id];
      setSelectedValue(newSelectedState);
      handleSelectChange(field, newSelectedState);
    },
    onError: () =>
      notification.error({
        message: 'Unable to save tag, try again later or contact us.',
      }),
  });
  useEffect(() => {
    if (mode === 'tags' && selectedValue) {
      const newName = selectedValue?.find(selected => typeof selected === 'string');
      if (newName) {
        savingTag.mutate({ companyId: globalStates?.companyId, name: newName });
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (dependsOn) {
      setSelectedValue([]);
      formInstance.resetFields([field]);
    }
  }, [dependsOnInputValue, formInstance, field, dependsOn]);

  const handleChange = value => {
    if (!Array.isArray(value)) return null;
    const newName = value?.find(selected => typeof selected === 'string');
    const duplicateOption = options.find(option => option.name === newName);
    if (!newName || !duplicateOption) {
      setSelectedValue(value);
    }
    if (duplicateOption) {
      if (!selectedValue?.includes(duplicateOption?.id)) {
        setSelectedValue(options.find(option => option.name === value)?.id);
      } else {
        setSelectedValue([...selectedValue]);
      }
    }
    return true;
  };

  return (
    <Form.Item hasFeedback label={label} name={field} rules={[{ required, message: `${label} is required` }]}>
      <Select
        value={selectedValue}
        allowClear
        size="large"
        mode={mode || null}
        onChange={handleChange}
        optionLabelProp="label"
        filterOption={(input, option) => option?.label?.includes(input)}
        disabled={dependsOn && !dependsOnInputValue}
      >
        {options?.map(option => {
          const optionLabel = option.name || option.title || option.mqtt;
          return (
            <Option value={option.id} label={optionLabel}>
              {optionLabel}
              {option.id !== selectedValue && field === 'Channel' && option.roomId ? ' (In use)' : ''}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SelectInput;
