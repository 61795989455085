import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, Modal, Spin, Table, Tooltip, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ModalContentContainer, RoomContentsContainer } from './style';
import ContainerForm, { containerFields, contentFields, fieldToOptions } from './container-form';
import { optifluxColors } from '../../../layout/colors';
import { TableWrapper } from '../../styled';
import { dashboardInnerTableColumns } from '../../../utility/helpers';
import DeleteConfirmationModal from '../../../components/modals/delete';
import { getItem, setItem } from '../../../utility/localStorageControl';

const { Title } = Typography;
const defaultEditState = { isEditing: false, content: null };
const defaultDeleteState = { visible: false, index: null };
const roomContentKey = 'roomContents';

const ConfigurationContainer = ({
  containerToConfigure,
  handleSave,
  handleDelete,
  handleCancel,
  setContainerToConfigure,
  savingContainer,
  existingContents,
}) => {
  const [isAddingContent, setIsAddingContent] = useState(false);
  const [editContent, setEditContent] = useState(defaultEditState);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const containerLocalStorageKey = containerToConfigure?.id || 'newContainer';
  const roomContentsLocalStorageKey = `${roomContentKey}${containerLocalStorageKey}`;
  const [localStorageContainer, localStorageRoomContents] = useMemo(
    () => [getItem(containerLocalStorageKey), getItem(roomContentsLocalStorageKey)],
    [containerLocalStorageKey, roomContentsLocalStorageKey],
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(!localStorageContainer && !localStorageRoomContents);
  const [currentRoomContents, setCurrentRoomContents] = useState(localStorageRoomContents || existingContents);
  const [deleteContent, setDeleteContent] = useState(defaultDeleteState);

  const selectOptions = useSelector(state => state.selectOptions);

  useEffect(() => {
    setCurrentRoomContents(getItem(roomContentsLocalStorageKey) || existingContents);
  }, [existingContents, roomContentsLocalStorageKey]);
  const handleEditField = useCallback(
    (field, value) => {
      setContainerToConfigure({
        ...containerToConfigure,
        [field]: value,
      });
    },
    [containerToConfigure, setContainerToConfigure],
  );

  const formToValues = (record, isContent) => {
    const converted = { ...record };
    const fieldsToUse = isContent ? contentFields : containerFields;
    fieldsToUse.forEach(contentField => {
      const { field, type, options } = contentField;
      const currentValue = record[field];
      const fieldOptions = options || fieldToOptions(contentField, selectOptions);
      if (type === 'select' && !options?.length) {
        if (!Array.isArray(currentValue)) {
          converted[`${field?.toLowerCase()}_id`] = currentValue;
          converted[field] = fieldOptions?.find(option => option.id === currentValue);
        } else {
          converted[field] = currentValue?.map(idOrName =>
            typeof idOrName === 'string'
              ? fieldOptions.find(option => {
                  const currentName = option.name || option.title || option.mqtt;
                  return currentName === idOrName;
                })
              : fieldOptions?.find(option => option.id === idOrName),
          );
        }
      }
      if (
        isContent &&
        field === 'smart_fresh' &&
        !converted.treatments?.find(treatment => treatment.title === 'SmartFresh')
      ) {
        converted[field] = null;
      }
    });
    return converted;
  };

  const handleSubmit = newRecord => {
    const recordWithContents = { ...formToValues(newRecord, false), room_contents: currentRoomContents };
    if (containerToConfigure?.id) {
      recordWithContents.id = containerToConfigure.id;
    }
    if (!(typeof recordWithContents?.is_optipallet === 'boolean')) {
      recordWithContents.is_optipallet = false;
    }
    handleSave(recordWithContents);
    setIsSaveDisabled(true);
  };

  const handleContentDelete = useCallback(index => {
    setDeleteContent({ visible: true, index });
  }, []);

  const handleDeleteContentConfirmation = useCallback(
    index => {
      const allContents = [...currentRoomContents];
      allContents.splice(index, 1);
      setCurrentRoomContents(allContents);
      setItem(roomContentsLocalStorageKey, allContents);
      setDeleteContent(defaultDeleteState);
    },
    [currentRoomContents, roomContentsLocalStorageKey],
  );

  const handleSubmitDisabled = isDisabled => {
    setIsSaveDisabled(isDisabled);
  };

  useEffect(() => {
    if (currentRoomContents && currentRoomContents?.length !== existingContents?.length) {
      setIsSaveDisabled(false);
    }
  }, [existingContents, currentRoomContents]);

  const handleContentSubmit = newRecord => {
    const convertedRecord = formToValues(newRecord, true);
    const contents = !editContent?.isEditing
      ? currentRoomContents?.length
        ? [...currentRoomContents, convertedRecord]
        : [convertedRecord]
      : currentRoomContents?.map(content => {
          if (content.id === editContent.content.id) {
            return { ...content, ...convertedRecord };
          }
          return content;
        }) || [];
    setCurrentRoomContents(contents);
    setItem(roomContentsLocalStorageKey, contents);
    setIsAddingContent(false);
    setEditContent(defaultEditState);
  };

  const handleContentCancel = () => {
    setIsAddingContent(false);
    setEditContent(defaultEditState);
  };

  const handleDeleteConfirmation = () => {
    setDeleteVisible(false);
    handleDelete(containerToConfigure?.id);
  };

  if (!containerToConfigure) {
    return <></>;
  }
  const { name } = containerToConfigure;
  const contentsToSave = (currentRoomContents?.length || 0) - (existingContents?.length || 0);
  return (
    <>
      <Modal
        title={name ? `Configure container ${name}` : 'Add container/room'}
        onCancel={handleCancel}
        visible={!!containerToConfigure}
        style={{ top: '5%' }}
        destroyOnClose
        footer={[
          <Button onClick={handleCancel}>Close</Button>,
          <Button
            disabled={!name}
            onClick={() => {
              setDeleteVisible(true);
            }}
            danger
          >
            Delete
          </Button>,
          <Button
            disabled={isSaveDisabled}
            form="container-configuration"
            htmlType="submit"
            type="primary"
            loading={savingContainer?.isLoading || savingContainer?.isPending}
            style={{ background: `${optifluxColors.lightGreen} !important`, color: 'black !important' }}
          >
            Save
          </Button>,
        ]}
        width="fit-content"
      >
        {savingContainer?.isLoading ||
          (savingContainer?.isPending && (
            <Col xs={24}>
              <div className="spin">
                <Spin />
              </div>
            </Col>
          ))}
        <ModalContentContainer>
          <ContainerForm
            containerToConfigure={localStorageContainer || containerToConfigure}
            handleEditField={handleEditField}
            handleSubmit={handleSubmit}
            handleSubmitDisabled={handleSubmitDisabled}
            key={`${containerToConfigure?.id}-container`}
          />
          <Title level={5}>Contents</Title>
          {contentsToSave > 0 && (
            <Tooltip>
              <Alert style={{ marginBottom: '1rem' }} message={`${contentsToSave} unsaved content(s)`} type="warning" />
            </Tooltip>
          )}
          <RoomContentsContainer>
            <TableWrapper className="table-responsive">
              <Table
                columns={dashboardInnerTableColumns(true, handleContentDelete, setEditContent)}
                dataSource={currentRoomContents}
                pagination={false}
                style={{ width: '100% !important' }}
              />
            </TableWrapper>
          </RoomContentsContainer>
          <Modal
            title="Configure content"
            onCancel={handleContentCancel}
            visible={isAddingContent || editContent.isEditing}
            footer={[
              <Button onClick={handleContentCancel}>Cancel</Button>,
              <Button
                form="content-configuration"
                htmlType="submit"
                type="primary"
                style={{ background: `${optifluxColors.lightGreen} !important`, color: 'black !important' }}
              >
                Confirm
              </Button>,
            ]}
            width={1080}
          >
            <ContainerForm
              content={editContent.isEditing ? editContent.content : null}
              isContent
              handleSubmit={handleContentSubmit}
              handleSubmitDisabled={handleSubmitDisabled}
              key={`${containerToConfigure?.id}-content`}
            />
          </Modal>

          <Tooltip title="Add content">
            <Button
              shape="circle"
              type="primary"
              color={optifluxColors.darkGreen}
              style={{ margin: '0.5rem 0' }}
              onClick={() => setIsAddingContent(true)}
            >
              <PlusOutlined style={{ fontSize: '1rem', color: optifluxColors.whiteBackground }} />
            </Button>
          </Tooltip>
          <DeleteConfirmationModal
            warningMessage={`Are you sure you want to delete the room or container named "${name}"?`}
            handleCancel={() => setDeleteVisible(false)}
            handleConfirm={handleDeleteConfirmation}
            visible={deleteVisible && !!containerToConfigure?.name}
            name={name}
            secure
          />
          <DeleteConfirmationModal
            warningMessage="Are you sure you want to delete this content?"
            handleCancel={() => setDeleteContent(defaultDeleteState)}
            handleConfirm={() => handleDeleteContentConfirmation(deleteContent?.index)}
            visible={deleteContent?.visible}
          />
        </ModalContentContainer>
      </Modal>
    </>
  );
};
export default ConfigurationContainer;
